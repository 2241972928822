<template>

  <div class="text-primary logo">
    <b-img
      :src="require('@/assets/logo/logo.png')"
      alt="logo"
      height="35"
    />
    Vatika ERP
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer'

export default {
  components: {
    BImg,
  },
  setup() {
    const {
      skin,
    } = useAppCustomizer()

    return {
      skin,
    }
  },
}
</script>

<style scoped>
.logo {
    font-weight: 600;
    letter-spacing: .01rem;
    font-size: 1.45rem;
    padding: 0.25rem;
}
</style>
